import './index.css';
import img from '@/assets/右上角箭头.png';

import { stopDefault } from '@/utils';

interface Props {
  text?: string;
  onClose: () => void;
}

export default ({ onClose, text }: Props) => {
  return (
    <div onTouchMove={stopDefault} class='wechat-guide' onClick={onClose}>
      <img src={img} alt='' />
      <div>
        <div>请点击右上角…</div>
        <div>{text || '再选择好友进行分享'}</div>
      </div>
    </div>
  );
};
