export const UA = navigator.userAgent.replace(/\s+/g, '').toLowerCase();

export const IS_ABC = /abcreading/.test(UA);

export const IS_ANDROID = /android/.test(UA);

export const IS_MINIAPP = /miniprogram/.test(UA);

export const IS_PC = /windows|macintosh/.test(UA);

export const IS_WECHAT = /micromessenger/.test(UA);

export const IS_IOS = /ipad|iphone|ipod|macos/.test(UA);

export const IS_WEWORK = IS_WECHAT && /wxwork/.test(navigator.userAgent);

export const IS_PAD = () => window.innerWidth >= 600 && window.innerHeight >= 600 && /pad/.test(UA);
