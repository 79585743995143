import { createSignal, Show } from 'solid-js';

import { IS_IOS, IS_WECHAT } from '@/utils/device';
import WeChatGuide from '@/components/WeChatGuide';
// import { hideLoading, showToast } from '@/utils/Toast';

import './index.css';

import check from './assets/勾选.png';
import head from './assets/头背景图.png';

export default () => {
  const [showWechatGuide, setShowWechatGuide] = createSignal(false);

  // const onOpen = async () => {
  //   if (IS_WECHAT) {
  //     setShowWechatGuide(true);
  //     return;
  //   }

  //   showToast({ type: 'loading', message: '跳转中', mask: true });

  //   const res = await new Promise(resolve => {
  //     location.href = 'abcreading://';
  //     window.addEventListener('pagehide', () => resolve(document.hidden), { once: true });
  //     window.addEventListener('visibilitychange', () => resolve(document.hidden), { once: true });
  //     window.addEventListener('webkitvisibilitychange', () => resolve(document.hidden), { once: true });
  //     const timer = window.setTimeout(() => {
  //       window.clearTimeout(timer);
  //       resolve(document.hidden);
  //     }, 3000);
  //   });

  //   hideLoading();

  //   if (!res) {
  //     showToast('跳转失败<br/>请手动打开或下载安装');
  //   }
  // };

  const onDownload = () => {
    if (IS_WECHAT) {
      setShowWechatGuide(true);
      return;
    }

    if (IS_IOS) {
      location.href = 'https://apps.apple.com/cn/app/abc-reading%E5%90%8C%E6%AD%A5%E7%BB%83/id6612010908';
      return;
    }

    const time = window?.QZOutputJson?.t || Date.now() / 1000;
    console.log('time', time);
    console.log('QZOutputJson', window?.QZOutputJson);

    if (time >= 1730297400) {
      location.href = 'https://file.abctime.com/download/exercises-2.0.0(103)-20241029-100900.官方.apk';
    } else {
      location.href = 'https://file.abctime.com/ara/3cf05886a26b9afe76bb0c35a741ba61.apk';
    }
  };

  return (
    <>
      <img src={head} alt='head' class='head' />

      <div class='main'>
        <div class='content'>
          <img src={`${_BASE_}logo.png`} alt='logo' class='logo' />
          <div class='title'>ABC Reading同步练</div>
          <div class='options'>
            <div class='option'>
              <img src={check} alt='check' />
              AI老师1对1指导
            </div>
            <div class='option'>
              <img src={check} alt='check' />
              教材配套同步练习
            </div>
            <div class='option'>
              <img src={check} alt='check' />
              真题实战训练
            </div>
            <div class='option'>
              <img src={check} alt='check' />
              拓展校内知识
            </div>
          </div>
          <div class='desc'> 专为小学生打造的英语课后练习软件，教材配套搭配AI老师，帮你更快进步!</div>
        </div>

        <div class='btn-group'>
          {/* <div class='open' onClick={onOpen}>
            打开APP
          </div> */}
          <div class='download' onClick={onDownload}>
            下载最新版
          </div>
        </div>
      </div>

      <div class='footer'>
        <a href='https://beian.miit.gov.cn/' target='_blank'>
          渝ICP备2023006780号
        </a>
        {/* <a href='https://beian.miit.gov.cn/' target='_blank'>
          重庆创浪时代未来科技有限公司
        </a> */}
        <a href='https://beian.mps.gov.cn/#/query/webSearch?code=50010802006235' rel='noreferrer' target='_blank'>
          渝公网安备50010802006235
        </a>
        {/* <a href='https://beian.mps.gov.cn/' target='_blank'>
          渝公网安备50010802005504号
        </a> */}
      </div>

      <Show when={showWechatGuide()}>
        <WeChatGuide text='并选择默认浏览器打开' onClose={() => setShowWechatGuide(false)} />
      </Show>
    </>
  );
};
